import React, { useEffect, useState, useContext } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';

import { appContext } from 'providers/appProvider';
import { Layout, Seo } from 'components';

// CUSTOM IMPORTS START
import SourceEmitter from 'libs/emitter';
import ContentBlock from 'components/Content/ContentBlock';
import CarouselNasHCP from 'components/CarouselNasHCP';
import BrandCTA from 'components/BrandCta';

// IMAGES START

import './index.scss';

// TRACKING
// const copayTracking = {
//   category: 'Button-Copay',
//   action: 'clicked on Button-Copay',
//   label: 'Download Copay Card - HCP',
// };
// const urlCopayTracking = {
//   category: 'URL-Copaycard',
//   action: 'clicked on URL-Copaycard',
//   label: 'Copay card - HCP Resource',
// };
// const enrollmentTracking = {
//   category: 'URL-Enrollment',
//   action: 'clicked on URL-Enrollment',
//   label: 'Download Enrollment Form - HCP Resource',
// };
// const brochureTracking = {
//   category: 'URL-PatientBrochure',
//   action: 'clicked on URL-PatientBrochure',
//   label: 'Download Patient Brochure - HCP Resource',
// };
// const requestTracking = {
//   category: 'URL-RequestARep',
//   action: 'clicked on Request A Rep Page',
//   label: 'Redirect to Request A Rep Page',
// };

const IndexPage = props => {
  let Subscription = null;
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialHeight, setInitialHeight] = useState('100vh');

  const { appConfigs } = useContext(appContext);
  const indication = appConfigs?.siteOptions?.INDICATIONS?.hcp;
  const metaData = appConfigs?.metaData?.hcp;

  /*  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  }; */

  const triggerHashChange = () => {
    const currentHash = window?.location?.hash?.substring(
      1,
      window?.location?.hash?.length,
    );
    SourceEmitter.emit('HashChangedEventTrigger', currentHash);
  };

  useEffect(() => {
    setIsLoaded(true);
    window.addEventListener('hashchange', triggerHashChange, false);

    // Triggered from Logo Component
    Subscription = SourceEmitter.addListener('gotoTopTriggerEvent', data => {
      goToAnchor('top');
      setInitialHeight('auto');
    });

    return () => {
      window.removeEventListener('hashchange', triggerHashChange, false);
      Subscription && Subscription.remove();
    };
  }, []);

  const renderPage = () => {
    return (
      isLoaded && (
        <div id="nascobal-hcp-home">
          <ContentBlock id="top">
            <Grid
              fluid
              style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
            >
              <CarouselNasHCP />

              <Row between="xs">
                <Col xs={12} md={6}>
                  <BrandCTA
                    href="/vitaminb12/hcp/b12-deficiency"
                    LinkCaption="WHAT IS VITAMIN B12 DEFICIENCY?"
                  >
                    WHAT IS VITAMIN B<sub className="vitaminb12-sub">12</sub>{' '}
                    DEFICIENCY?
                  </BrandCTA>
                </Col>
                <Col xs={12} md={6}>
                  <BrandCTA
                    ScrollLink
                    href="/vitaminb12/hcp/about"
                    LinkCaption="SEE HOW NASCOBAL® MAY HELP"
                  >
                    SEE HOW NASCOBAL<sup className="brand-reg-mark">&reg;</sup>{' '}
                    MAY HELP
                  </BrandCTA>
                </Col>
              </Row>
            </Grid>
          </ContentBlock>

          {/* Required for ISI Anchor Link */}
          <ScrollableAnchor id="isi_anchor">
            <div />
          </ScrollableAnchor>
        </div>
      )
    );
  };
  const bodyHeight = {
    // height: initialHeight,
  };
  return (
    <Layout indication={indication} className="hcp-home">
      <Seo {...metaData} />
      <div className="body-content-container" style={bodyHeight}>
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default IndexPage;
